<template>
  <div id="MyPage" class="wrap">
    <div class="formWrap">
      <h1 class="title" v-if="isEdit">設定 - ユーザー情報の編集</h1>
      <h1 class="title" v-else>設定 - ユーザー情報</h1>
      <div class="inputBox">
        <v-form-name
          v-if="isEdit"
          label="氏名"
          :left.sync="sei"
          :right.sync="mei"
          :placeholders="{left: '例）山田' , right: '例）太郎'}"
          :errors="{left: errors.sei || '', right: errors.mei || ''}"
          :isRequired="true"
        >
        </v-form-name>
        <v-show-data
         v-else
         label="氏名"
         :data="sei + blank + mei"
        ></v-show-data>
        <hr>
        <v-form-name
          v-if="isEdit"
          label="フリガナ"
          :left.sync="kanaSei"
          :right.sync="kanaMei"
          :placeholders="{left: '例）ヤマダ' , right: '例）タロウ'}"
          :errors="{left: errors.kanaSei || '', right: errors.kanaMei || ''}"
          :isRequired="true"
        >
        </v-form-name>
        <v-show-data
         v-else
         label="フリガナ"
         :data="kanaSei + blank + kanaMei"
        ></v-show-data>
        <hr>
        <v-form-barth
          v-if="isEdit"
          label="生年月日"
          name="barth"
          :placeholders="{year: '例）1980', month: '例）10', day: '例）10'}"
          :errMessage="errors.barth || []"
          :isRequired="true"
          v-model="barth">
        </v-form-barth>
        <v-show-data
         v-else
         label="生年月日"
         :data="barth"
        ></v-show-data>
        <hr>
        <div v-if="isEdit" class="container-fluid itemWrap">
          <b-row class="textData genderItemWrap">
            <b-col sm="5" class="labelWrap">
              <label>性別</label>
            </b-col>
            <b-col sm="8" class="pl-20 textWrap">
              <b-form-select
                  :value="gender"
                  @input="updateGender"
                  :class="[errors.gender ? 'errItem' : '', 'gender']"
                  :options="genderList"
                />
            </b-col>
          </b-row>
          <b-row
            v-for="(msg, index) in errors.gender" :key="'gender' + index">
            <div class="label pl-0 pr-1 mb-0"></div>
            <div class="pl-5">
              <p class="error my-1">{{msg}}</p>
            </div>
          </b-row>
          <span class="genderComm">* 選択は任意です。未選択とすることも可能です。</span>
        </div>
        <v-show-data
         v-else
         label="性別"
         :data="genderText[gender] || ''"
        ></v-show-data>
        <hr>
        <v-form-text
          v-if="isEdit"
          label="学位"
          itemStyle="line"
          maxLength="100"
          placeholder="例）博士（工学）"
          :errMessage="errors.degree || []"
          v-model="degree"
        ></v-form-text>
        <v-show-data
         v-else
         label="学位"
         :data="degree"
        ></v-show-data>

        <hr>
        <v-form-text
          v-if="isEdit"
          label="自宅 郵便番号"
          itemStyle="line"
          inputClass="w-small"
          maxLength="7"
          placeholder="例）5410041"
          :errMessage="errors.postalCode || []"
          v-model="postalCode"
        ></v-form-text>
        <v-show-data
         v-else
         label="自宅 郵便番号"
         :data="postalCode"
        ></v-show-data>

        <hr>
        <v-form-text
          v-if="isEdit"
          label="自宅 住所"
          itemStyle="line"
          inputClass="w-long"
          maxLength="500"
          placeholder="例）大阪府xx市xx区xx町1-2-3"
          :errMessage="errors.address || []"
          v-model="address"
        ></v-form-text>
        <v-show-data
         v-else
         label="自宅 住所"
         :data="address"
        ></v-show-data>
        <hr class="double">
        <b-row v-if="isEdit" class="mt-2 mx-4 institutionNameWrap">
          <b-col sm="3" class="labelWrap">
            <div class="label">
              <label>所属機関 名称</label>
            </div>
          </b-col>
          <b-col sm="9">
            <div class="itemWrap mt-1">
              <div class="itemName">大学、研究機関 等</div>
              <b-form-input
                placeholder="例）xxxx大学"
                maxLength="100"
                v-model="institutionName"
              >
              </b-form-input>
            </div>
            <span class="error fw-3" v-if="errors.institutionName">
              {{ errors.institutionName[0] }}
            </span>
            <div class="itemWrap">
              <div class="itemName">学部、研究所、センター 等</div>
              <b-form-input
                placeholder="例）大学院工学研究科"
                maxLength="100"
                v-model="institutionName2"
              ></b-form-input>
            </div>
            <span class="error fw-3" v-if="errors.institutionName2">
              {{ errors.institutionName2[0] }}
            </span>
            <div class="itemWrap mb-1">
              <div class="itemName">学科・専攻、研究室、グループ、部門 等</div>
              <b-form-input
                placeholder="例）機械物理系専攻"
                maxLength="100"
                v-model="institutionName3"
              ></b-form-input>
            </div>
            <span class="error fw-3 mt-0" v-if="errors.institutionName3">
              {{ errors.institutionName3[0] }}
            </span>
          </b-col>
        </b-row>
        <b-container v-else fluid>
          <b-row class="textData institutionNameText">
            <b-col sm="3" class="labelWrap">
              <label>所属機関 名称</label>
            </b-col>
            <b-col sm="8" class="pl-20 textWrap">
              <div>{{institutionName}}</div>
              <div>{{institutionName2}}</div>
              <div>{{institutionName3}}</div>
            </b-col>
          </b-row>
        </b-container>
        <hr>
        <v-form-text
          v-if="isEdit"
          label="職位"
          itemStyle="line"
          maxLength="100"
          placeholder="例）准教授"
          :errMessage="errors.position || []"
          v-model="position"
        ></v-form-text>
        <v-show-data
         v-else
         label="職位"
         :data="position"
        ></v-show-data>

        <hr>
        <v-form-text
          v-if="isEdit"
          label="所属機関 郵便番号"
          itemStyle="line"
          inputClass="w-small"
          maxLength="7"
          placeholder="例）5410041"
          :errMessage="errors.institutionPostalCode || []"
          v-model="institutionPostalCode"
        ></v-form-text>
        <v-show-data
         v-else
         label="所属機関 郵便番号"
         :data="institutionPostalCode"
        ></v-show-data>

        <hr>
        <v-form-text
          v-if="isEdit"
          label="所属機関 住所"
          itemStyle="line"
          inputClass="w-long"
          maxLength="500"
          placeholder="例）大阪府xx市xx区xx町1-2-3"
          :errMessage="errors.institutionAddress || []"
          v-model="institutionAddress"
        ></v-form-text>
        <v-show-data
         v-else
         label="所属機関 住所"
         :data="institutionAddress"
        ></v-show-data>

        <hr>
        <v-form-text
          v-if="isEdit"
          label="所属機関 電話番号"
          itemStyle="line"
          maxLength="15"
          placeholder="例）0662223380"
          :errMessage="errors.institutionTel || []"
          v-model="institutionTel"
        ></v-form-text>
        <v-show-data
         v-else
         label="所属機関 電話番号"
         :data="institutionTel"
        ></v-show-data>

        <hr>
        <v-form-text
          v-if="isEdit"
          label="所属機関 FAX番号"
          itemStyle="line"
          maxLength="15"
          placeholder="例）0662223380"
          :errMessage="errors.institutionFax || []"
          v-model="institutionFax"
        ></v-form-text>
        <v-show-data
         v-else
         label="所属機関 FAX番号"
         :data="institutionFax"
        ></v-show-data>
        <hr>
        <v-form-text
          v-if="isEdit"
          label="所属機関 最寄駅"
          itemStyle="line"
          maxLength="50"
          placeholder="例）JR大阪駅"
          :errMessage="errors.institutionStation || []"
          v-model="institutionStation"
        ></v-form-text>
        <v-show-data
         v-else
         label="所属機関 最寄駅"
         :data="institutionStation"
        ></v-show-data>
        <hr class="double">
        <v-form-text
          v-if="isEdit"
          label="不在時連絡先 氏名"
          itemStyle="line"
          maxLength="100"
          placeholder="例）田中  春子"
          :errMessage="errors.alternativeName || []"
          v-model="alternativeName"
        ></v-form-text>
        <v-show-data
         v-else
         label="不在時連絡先 氏名"
         :data="alternativeName"
        ></v-show-data>
        <hr>
        <v-form-text
          v-if="isEdit"
          label="不在時連絡先
          電話番号"
          itemStyle="line"
          maxLength="15"
          placeholder="例）0662223380"
          :errMessage="errors.alternativeTel || []"
          v-model="alternativeTel"
        ></v-form-text>
        <v-show-data
          v-else
          label="不在時連絡先
          電話番号"
          :data="alternativeTel"
        ></v-show-data>
        <hr>
        <v-form-text
          v-if="isEdit"
          label="不在時連絡先
          所属機関"
          itemStyle="line"
          inputClass="w-long"
          maxLength="100"
          placeholder="例）xxxx大学  大学院工学研究科"
          :errMessage="errors.alternativeInstitution || []"
          v-model="alternativeInstitution"
        ></v-form-text>
        <v-show-data
         v-else
         label="不在時連絡先
         所属機関"
         :data="alternativeInstitution"
        ></v-show-data>
        <hr>
      </div>

      <div v-if="isEdit" class="btnWrap">
        <b-button
          class="btn cancel bold"
          @click="inputReset"
        >
          キャンセル
        </b-button>
        <b-button
          class="btn save bold"
          @click="save"
          :disabled="!isChange"
        >
          保存
        </b-button>
      </div>
      <div v-else class="btnWrap">
        <b-button
          class="btn back bold"
          @click="$router.push({ name: 'gasTop' })"
        >
          トップに戻る
        </b-button>
        <b-button
          class="btn save bold"
          @click="edit"
        >
          編集する
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Core as YubinBangoCore } from 'yubinbango-core';
import api from '@/modules/api';
import CONST_OPTIONS from '@/constants/options';

export default {
  name: 'GasMyPage',
  data() {
    return {
      isEdit: false,
      blank: '　',
      isInit: [true, true],
    };
  },
  async created() {
    this.$store.dispatch('page/onLoading');
    this.$store.commit('userInfo/resetErrorMsg');
    this.selectedTab = this.$route.name;
    const res = await api.send('/api/user/myInfo')
      .catch((error) => {
        console.log(error);
        return false;
      });
    if (res !== false) {
      this.$store.commit('userInfo/setDbData', res.data.userInfo);
    }
    this.$store.commit('userInfo/changeCheck');
    this.$store.dispatch('page/offLoading');
  },
  methods: {
    edit() {
      window.scroll(0, 0);
      this.isEdit = true;
    },
    async inputReset() {
      const CONF_MSG = '入力内容は破棄されます。このページから移動してもよろしいですか？';
      if (!this.isChange || await this.confirm(CONF_MSG)) {
        this.$store.commit('userInfo/resetInputData');
        this.$store.commit('userInfo/changeCheck');
        this.$store.commit('userInfo/resetErrorMsg');
        this.isEdit = false;
      }
    },
    async save() {
      if (!await this.confirm('現在の内容で保存してよろしいですか？')) {
        return;
      }
      window.scroll(0, 0);
      this.$store.dispatch('page/onWaiting');
      this.$store.commit('userInfo/resetErrorMsg');
      const params = this.$store.state.userInfo.inputData;
      const res = await api.send('/api/user/myInfo/save', params)
        .catch(async (error) => {
          this.$store.commit('userInfo/setErrorMsg', error.response.data.message);
          this.$store.dispatch('page/offWaiting');
          if (error.response.data.isAlert) {
            await this.alert(this.errors.errorMessage);
          }
          console.log(error);
          return false;
        });
      this.$store.dispatch('page/offWaiting');
      if (res !== false) {
        this.$store.commit('userInfo/setSavedData');
        this.$store.commit('userInfo/changeCheck');
        this.isEdit = false;
        await this.alert('保存が完了しました。');
      }
    },
    updateGender(value) {
      this.$store.commit('userInfo/updateGender', value);
      this.$store.commit('userInfo/changeCheck');
    },
  },
  watch: {
    postalCode(newPostalCode) {
      // 画面描画時は走らないように制御
      if (this.isInit[0]) {
        this.isInit[0] = false;
        return false;
      }
      if (newPostalCode.length < 7 || newPostalCode.search(/^[0-9]+$/) === -1) {
        return false;
      }
      const YUBIN_DATA = new YubinBangoCore(newPostalCode, (addr) => {
        if (addr.region || addr.locality || addr.street) {
          this.address = addr.region + addr.locality + addr.street;
        }
      });
      return YUBIN_DATA;
    },
    institutionPostalCode(newPostalCode) {
      // 画面描画時は走らないように制御
      if (this.isInit[1]) {
        this.isInit[1] = false;
        return false;
      }
      if (newPostalCode.length < 7 || newPostalCode.search(/^[0-9]+$/) === -1) {
        return false;
      }
      const YUBIN_DATA = new YubinBangoCore(newPostalCode, (addr) => {
        if (addr.region || addr.locality || addr.street) {
          this.institutionAddress = addr.region + addr.locality + addr.street;
        }
      });
      return YUBIN_DATA;
    },
  },
  computed: {
    sei: {
      get() {
        return this.$store.state.userInfo.inputData.sei;
      },
      set(value) {
        this.$store.commit('userInfo/updateSei', value);
        this.$store.commit('userInfo/changeCheck');
      },
    },
    mei: {
      get() {
        return this.$store.state.userInfo.inputData.mei;
      },
      set(value) {
        this.$store.commit('userInfo/updateMei', value);
        this.$store.commit('userInfo/changeCheck');
      },
    },
    kanaSei: {
      get() {
        return this.$store.state.userInfo.inputData.kanaSei;
      },
      set(value) {
        this.$store.commit('userInfo/updateKanaSei', value);
        this.$store.commit('userInfo/changeCheck');
      },
    },
    kanaMei: {
      get() {
        return this.$store.state.userInfo.inputData.kanaMei;
      },
      set(value) {
        this.$store.commit('userInfo/updateKanaMei', value);
        this.$store.commit('userInfo/changeCheck');
      },
    },
    barth: {
      get() {
        return this.$store.state.userInfo.inputData.barth;
      },
      set(value) {
        this.$store.commit('userInfo/updateBarth', value);
        this.$store.commit('userInfo/changeCheck');
      },
    },
    position: {
      get() {
        return this.$store.state.userInfo.inputData.position;
      },
      set(value) {
        this.$store.commit('userInfo/updatePosition', value);
        this.$store.commit('userInfo/changeCheck');
      },
    },
    degree: {
      get() {
        return this.$store.state.userInfo.inputData.degree;
      },
      set(value) {
        this.$store.commit('userInfo/updateDegree', value);
        this.$store.commit('userInfo/changeCheck');
      },
    },
    postalCode: {
      get() {
        return this.$store.state.userInfo.inputData.postalCode;
      },
      set(value) {
        this.$store.commit('userInfo/updatePostalCode', value);
        this.$store.commit('userInfo/changeCheck');
      },
    },
    address: {
      get() {
        return this.$store.state.userInfo.inputData.address;
      },
      set(value) {
        this.$store.commit('userInfo/updateAddress', value);
        this.$store.commit('userInfo/changeCheck');
      },
    },
    institutionName: {
      get() {
        return this.$store.state.userInfo.inputData.institutionName;
      },
      set(value) {
        this.$store.commit('userInfo/updateInstitutionName', value);
        this.$store.commit('userInfo/changeCheck');
      },
    },
    institutionName2: {
      get() {
        return this.$store.state.userInfo.inputData.institutionName2;
      },
      set(value) {
        this.$store.commit('userInfo/updateInstitutionName2', value);
        this.$store.commit('userInfo/changeCheck');
      },
    },
    institutionName3: {
      get() {
        return this.$store.state.userInfo.inputData.institutionName3;
      },
      set(value) {
        this.$store.commit('userInfo/updateInstitutionName3', value);
        this.$store.commit('userInfo/changeCheck');
      },
    },
    institutionPostalCode: {
      get() {
        return this.$store.state.userInfo.inputData.institutionPostalCode;
      },
      set(value) {
        this.$store.commit('userInfo/updateInstitutionPostalCode', value);
        this.$store.commit('userInfo/changeCheck');
      },
    },
    institutionAddress: {
      get() {
        return this.$store.state.userInfo.inputData.institutionAddress;
      },
      set(value) {
        this.$store.commit('userInfo/updateInstitutionAddress', value);
        this.$store.commit('userInfo/changeCheck');
      },
    },
    institutionStation: {
      get() {
        return this.$store.state.userInfo.inputData.institutionStation;
      },
      set(value) {
        this.$store.commit('userInfo/updateInstitutionStation', value);
        this.$store.commit('userInfo/changeCheck');
      },
    },
    institutionTel: {
      get() {
        return this.$store.state.userInfo.inputData.institutionTel;
      },
      set(value) {
        this.$store.commit('userInfo/updateInstitutionTel', value);
        this.$store.commit('userInfo/changeCheck');
      },
    },
    institutionFax: {
      get() {
        return this.$store.state.userInfo.inputData.institutionFax;
      },
      set(value) {
        this.$store.commit('userInfo/updateInstitutionFax', value);
        this.$store.commit('userInfo/changeCheck');
      },
    },
    alternativeName: {
      get() {
        return this.$store.state.userInfo.inputData.alternativeName;
      },
      set(value) {
        this.$store.commit('userInfo/updateAlternativeName', value);
        this.$store.commit('userInfo/changeCheck');
      },
    },
    alternativeTel: {
      get() {
        return this.$store.state.userInfo.inputData.alternativeTel;
      },
      set(value) {
        this.$store.commit('userInfo/updateAlternativeTel', value);
        this.$store.commit('userInfo/changeCheck');
      },
    },
    alternativeInstitution: {
      get() {
        return this.$store.state.userInfo.inputData.alternativeInstitution;
      },
      set(value) {
        this.$store.commit('userInfo/updateAlternativeInstitution', value);
        this.$store.commit('userInfo/changeCheck');
      },
    },
    errors() {
      return this.$store.state.userInfo.errors;
    },
    isChange() {
      return this.$store.state.userInfo.isChange;
    },
    genderList() {
      return CONST_OPTIONS.GENDER_TYPE_OPTION;
    },
    genderText() {
      return CONST_OPTIONS.GENDER_TEXT;
    },
    gender() {
      return this.$store.state.userInfo.inputData.gender;
    },
  },
};
</script>


<style scoped>
span.genderComm {
    margin-left: 260px;
    display: block;
    text-align: left;
    width: 400px;
    font-size: 13px;
    margin-top: -11px;
}
.gender{
  padding: 0 20px;
  width: 150px;
  height: 50px;
}
.title{
  color: #333333;
  font-weight: 300;
  font-size: 24px;
  margin: 0 100px;
  margin-top: 52px;
  border-bottom: solid 2px #A0A9D0;
  padding-left: 10px;
  padding-bottom: 7px;
}
.inputBox{
  text-align: center;
  margin:0 140px;
  margin-top: 53px;
}
button.btn{
  font-size: 18px;
  font-weight: 600;
  margin: 15px;
}
button.save{
  background-color: #122889!important;
  color: #FFFFFF!important;
}
.btnWrap{
  text-align: center;
  margin-top: 45px;
}
.institutionNameWrap{
  width: 975px;
}
.institutionNameWrap .labelWrap{
  padding: 5px;
  max-width: 180px;
  width: 180px;
  margin-right: 55px;
}
.institutionNameWrap .labelWrap .label{
  line-height: 40px;
  font-weight: bold;
}
.institutionNameWrap .itemWrap{
  display: flex;
  margin-top:15px;
  margin-bottom:15px;
}
.institutionNameWrap .itemWrap input[type="text"]{
  width: 400px;
  height: 50px;
}
.institutionNameWrap .itemWrap div.itemName{
  width: 100%;
  max-width: 300px;
  text-align: left;
  line-height: 50px;
  margin-right: 10px;
}
.institutionNameWrap .error{
  font-size: 12.8px;
  color: #dc3545;
  display: block;
  width: 395px;
  margin-top: -12px;
  text-align: left;
  margin-left: 312px;
}
.institutionNameText .labelWrap{
  text-align: left;
  width: 180px;
  max-width: 180px;
  font-weight: bold;
  white-space: pre-line;
  margin-right: 30px;
}
.institutionNameText{
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 15px auto;
  word-break: break-all;
}
.institutionNameText .textWrap{
  text-align: left;
}
.institutionNameText .textWrap div{
  padding-left: 5px;
}
.institutionNameText label{
  margin-bottom: 0;
}

.genderItemWrap .labelWrap{
  text-align: left;
  width: 180px;
  max-width: 237px;
  font-weight: bold;
  white-space: pre-line;
}
.genderItemWrap{
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 15px auto;
  word-break: break-all;
}
.genderItemWrap .textWrap{
  text-align: left;
  padding: 0 20px;
}
.genderItemWrap .textWrap div{
  padding-left: 5px;
}
.genderItemWrap label{
  margin-bottom: 0;
}
</style>
